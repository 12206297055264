import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "reactstrap";
import "./FONTS/SamsungOne-200_v1.0.ttf";
import "./form.css";

import "filepond/dist/filepond.min.css";
import Dropzone from "react-dropzone";

export default function Formulario() {
  const [guardarprovincias, setGuardarprovincias] = useState();
  const [guardarmunicipio, setGuardarmunicipio] = useState();
  const [parroquias, setParroquias] = useState([]);
  const [guardarparroquia, setGuardarparroquia] = useState(0);
  const [numeroSeleccionadoPais, setNumeroSeleccionadoPais] = useState("+506");
  const [termsCondition, setTermsCondition] = useState(false);
  const [termsConditionError, setTermsConditionError] = useState(false);
  const [newsletter, setNewsletter] = useState(false);

  const [selectedTienda, setSelectedTienda] = useState("");
  const [tiendas, setTiendas] = useState([]);

  const [selectedFiles, setselectedFiles] = useState([]);

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenTerminos, setIsOpenTerminos] = useState(false);
  const [value, setValue] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const handleChange = (e) => {
    const newValue = e.target.value;
    const onlyText = newValue.replace(/[^a-zA-Z\s]/g, "");
    setValue(onlyText);
    setShowMessage(false); // Ocultamos el mensaje al cambiar el valor

    const newCuponValue = e.target.value;
    const cuponLength = newCuponValue.length;

    if (cuponLength < 9) {
      setShowMessage(true);
    } else {
      setShowMessage(false);
    }
  };

  const [showMessageCedula, setShowMessageCedula] = useState(false);

  const limiteCaracteresCedula = 20;

  const handleChangeCedula = (e) => {
    const newValue = e.target.value.slice(0, limiteCaracteresCedula);
    const onlyText = newValue.replace(/[^a-zA-Z\s]/g, "");
    setDocumento(newValue);
    setShowMessage(false); // Ocultamos el mensaje al cambiar el valor

    const newCuponValue = e.target.value;
    const cuponLength = newCuponValue.length;

    if (cuponLength < 7) {
      setShowMessageCedula(true);
    } else {
      setShowMessageCedula(false);
    }
  };

  const handleBlur = () => {
    if (!value.trim()) {
      setShowMessage(true);
    } else {
      setShowMessage(false);
    }
  };

  /**************************************/

  const [valueEmail, setValueEmail] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChangeEmail = (e) => {
    const newValue = e.target.value;
    setValueEmail(newValue);

    // Validar formato de correo electrónico
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(newValue);

    setIsValid(isValidEmail);
    setErrorMessage(
      !isValidEmail ? "El formato de correo electrónico no es válido" : ""
    );
    setIsValidEmailEnviar(false);
  };

  const handleBlurEmail = () => {
    if (!valueEmail.trim()) {
      setValueEmail("");
      setIsValid(false);
      setErrorMessage("El formato de correo electrónico no es válido");
    }
  };

  /***********************************************************/

  const [nombreApellido, setNombreApellido] = useState("");
  const [documento, setDocumento] = useState("");
  const [correo, setCorreo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [cupon, setCupon] = useState("");
  const [factura, setFactura] = useState("");
  const [pais, setPais] = useState("");
  const [direccion, setDireccion] = useState("");

  const [mostrar, setmostrar] = useState(false);

  const limiteCaracteres = 8;

  const [mostrarErrorTelefono, setMostrarErrorTelefono] = useState(false);

  const handleOnChange = (e) => {
    const nuevoValor = e.target.value.slice(0, limiteCaracteres);
    setTelefono(nuevoValor);

    const newCuponValue = e.target.value;
    const cuponLength = newCuponValue.length;

    if (cuponLength < 7) {
      setMostrarErrorTelefono(true);
    } else {
      setMostrarErrorTelefono(false);
    }
  };

  const handleOnChangeCupon = (e) => {
    const nuevoValor = e.target.value.slice();
    setCupon(nuevoValor);

    const newCuponValue = e.target.value;
    const cuponLength = newCuponValue.length;

    if (cuponLength < 7) {
      setmostrar(true);
    } else {
      setmostrar(false);
    }
  };

  const [mostrarfactura, setMostrarfactura] = useState(false);

  const handleOnChangeFactura = (e) => {
    const nuevoValor = e.target.value.slice();
    setFactura(nuevoValor);

    const newfacturaValue = e.target.value;
    const facturaLength = newfacturaValue.length;

    if (facturaLength < 7) {
      setMostrarfactura(true);
    } else {
      setMostrarfactura(false);
    }
  };

  const [mostrarerrorD, setMostrarerrorD] = useState(false);

  const handleOnChangeDireccion = (e) => {
    const nuevoValor = e.target.value.slice();
    setDireccion(nuevoValor);

    const newfacturaValue = e.target.value;
    const facturaLength = newfacturaValue.length;

    if (facturaLength < 14) {
      setMostrarerrorD(true);
    } else {
      setMostrarerrorD(false);
    }
  };

  /************Fecha************/

  const [fechaActual, setFechaActual] = useState("");

  useEffect(() => {
    const actualizarFecha = () => {
      const fecha = new Date();
      const anio = fecha.getFullYear();
      const mes = String(fecha.getMonth() + 1).padStart(2, "0");
      const dia = String(fecha.getDate()).padStart(2, "0");

      const fechaFormateada = `${anio}-${mes}-${dia}`;
      setFechaActual(fechaFormateada);
    };

    actualizarFecha();

    // Actualizar cada hora
    const intervalo = setInterval(actualizarFecha, 3600000);

    return () => clearInterval(intervalo);
  }, []);

  const [mostrarErrorImagen, setMostrarErrorImagen] = useState(false);

  const [datos, setDatos] = useState({
    data: {
      name: value,
      id_number: documento,
      email: valueEmail,
      phone: numeroSeleccionadoPais + telefono,
      code: cupon,
      invoice_code: factura,
      country: "Costa Rica",
      address: direccion,
      privacy: termsCondition,
      contact: newsletter,
      date_registry: fechaActual,
      provincia: guardarprovincias,
      canton: guardarmunicipio,
      distrito: guardarparroquia,
      referencia: "string",
      tienda: selectedTienda,
    },
  });

  const actualizarDatos = () => {
    setDatos({
      data: {
        name: value,
        id_number: documento,
        email: valueEmail,
        phone: numeroSeleccionadoPais + telefono,
        code: cupon,
        invoice_code: factura,
        country: "Costa Rica",
        address: direccion,
        privacy: termsCondition,
        contact: newsletter,
        date_registry: fechaActual,
        status: "Recibido",
        provincia: guardarprovincias,
        canton: guardarmunicipio,
        distrito: guardarparroquia,
        tienda: selectedTienda,
      },
    });
  };

  useEffect(() => {
    actualizarDatos();
  }, [
    fechaActual,
    value,
    valueEmail,
    telefono,
    cupon,
    factura,
    selectedFiles,
    guardarparroquia,
    guardarmunicipio,
    parroquias,
    direccion,
    selectedTienda,
    newsletter,
    termsCondition,
    actualizarDatos,
  ]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDatos((prevData) => ({
      ...prevData,
      data: {
        ...prevData.data,
        [name]: value,
      },
    }));
  };

  /*    async function enviarDatos() {
            console.log("datos 1", datos)
    
            const archivo = selectedFiles[0];
    
            const invoicePicData = {
                id: Date.now(), // Generar un ID único temporal
                attributes: {
                    name: archivo.name,
                    alternativeText: "Factura de compra",
                    caption: "Factura de compra para redimir cupón",
                    width: archivo.width || 0,
                    height: archivo.height || 0,
                    formats: "",
                    hash: archivo.hash || "",
                    ext: archivo.ext || archivo.name.split('.').pop(),
                    mime: archivo.type || "",
                    size: archivo.size || 0,
                    url: "", // Dejar vacío ya que el servidor lo manejará
                    previewUrl: "", // Dejar vacío ya que el servidor lo manejará
                    provider: "local",
                    provider_metadata: JSON.stringify({}),
                    folder: { data: null },
                    createdAt: new Date().toISOString(),
                    updatedAt: new Date().toISOString(),
                    createdBy: { data: null },
                    updatedBy: { data: null }
                }
            };
    
            const formData = new FormData();
            
            try {
                const formData = new FormData();
      
                formData.append('invoice_pic', JSON.stringify(invoicePicData));
                // Agregar otros campos si es necesario
                formData.append('name', datos.data.name);
                formData.append('id_number', datos.data.id_number);
                formData.append('email', datos.data.email);
                formData.append('phone', datos.data.phone);
                formData.append('code', datos.data.code);
                formData.append('invoice_code', datos.data.invoice_code);
                formData.append('country', datos.data.country);
                formData.append('address', datos.data.address);
                formData.append('privacy', datos.data.privacy.toString());
                formData.append('contact', datos.data.contact.toString());
                formData.append('date_registry', datos.data.date_registry);
                formData.append('date_approval', datos.data.date_approval);
                formData.append('status', datos.data.status.toString());
    
                const response = await fetch('https://cms-canje-dev.darienc.com/api/canjes', {
                    method: 'POST',
                    body: datos
                });
    
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}, body: ${await response.text()}`);
                }
    
                const result = await response.json();
                console.log(result);
                setIsOpen(true)
            } catch (error) {
                console.error('Error:', error);
                alert("Hubo un error al enviar los datos. Detalles del error: " + error.message);
            } 
        }*/

  /************************************************** */

  async function enviarDatos() {
    try {
      const formData = new FormData();

      formData.append(
        `files.invoice_pic`,
        selectedFiles[0],
        selectedFiles[0].name
      );
      formData.append("data", JSON.stringify(datos.data));

      const response = await fetch(
        "https://cms-canje-dev.darienc.com/api/canjes",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(
          `HTTP error! status: ${
            response.status
          }, body: ${await response.text()}`
        );
      }

      const result = await response.json();
      console.log("🚀 ~ enviarDatos ~ result:", result);

      setIsOpen(true);
    } catch (error) {
      console.error("Error:", error);
      alert(
        "Hubo un error al enviar los datos. Detalles del error: " +
          error.message
      );
    }
  }

  const recargarPagina = () => {
    /*setValue("")
        setValueEmail("")
        setTelefono("")
        setCupon("")
        setFactura("")
        setGuardarprovincias("")
        setGuardarmunicipio("")
        setGuardarparroquia("")
        setSelectedTienda("")
        setselectedFiles([]);*/

    window.location.reload();
    setIsOpen(false);
  };

  /************************************************* */

  const [provincias, setProvincias] = useState([
    "San José",
    "Alajuela",
    "Cartago",
    "Heredia",
    "Guanacaste",
    "Puntarenas",
    "Limón",
  ]);
  const [municipios, setMunicipios] = useState([]);
  const [canton, setCanton] = useState([]);

  const handleSelectNumero = (event) => {
    setNumeroSeleccionadoPais(event.target.value);
  };

  const handleProvinciaChange = (event) => {
    setParroquias([]);
    const provinciaSeleccionada = event.target.value;
    setGuardarprovincias(event.target.value);
    // Obtenemos los municipios correspondientes a la provincia seleccionada
    let municipiosPorProvincia = {
      "San José": [
        "San José",
        "Escazú",
        "Desamparados",
        "Puriscal",
        "Tarrazú",
        "Aserrí",
        "Mora",
        "Goicoechea",
        "Santa Ana",
        "Alajuelita",
        "Tibás",
        "Moravia",
        "Montes de Oca",
        "Turrubares",
        "Dota",
        "Curridabat",
        "Pérez Zeledón",
        "León Cortés Castro",
      ],
      Alajuela: [
        "Alajuela",
        "San Ramón",
        "Grecia",
        "San Mateo",
        "Atenas",
        "Naranjo",
        "Palmares",
        "Poás",
        "Orotina",
        "San Carlos",
        "Zarcero",
        "Sarchí",
        "Upala",
        "Los Chiles",
        "Guatuso",
        "Río Cuarto",
      ],
      Cartago: [
        "Cartago",
        "Paraíso",
        "La Unión",
        "Jiménez",
        "Turrialba",
        "Alvarado",
        "Oreamuno",
        "El Guarco",
      ],
      Heredia: [
        "Heredia",
        "Barva",
        "Santo Domingo",
        "Santa Bárbara",
        "San Rafael",
        "San Isidro",
        "Belén",
        "Flores",
        "San Pablo",
        "Sarapiquí",
      ],
      Guanacaste: [
        "Liberia",
        "Nicoya",
        "Santa Cruz",
        "Bagaces",
        "Carrillo",
        "Cañas",
        "Abangares",
        "Tilarán",
        "Nandayure",
        "La Cruz",
        "Hojancha",
      ],
      Puntarenas: [
        "Puntarenas",
        "Esparza",
        "Buenos Aires",
        "Montes de Oro",
        "Osa",
        "Quepos",
        "Golfito",
        "Coto Brus",
        "Parrita",
        "Corredores",
        "Garabito",
        "Monteverde",
      ],
      Limón: ["Limón", "Pococí", "Siquirres", "Talamanca", "Matina", "Guácimo"],
    };

    // Obtenemos los municipios correspondientes a la provincia seleccionada
    setMunicipios(municipiosPorProvincia[provinciaSeleccionada]);
  };

  const [mostrarErrorDireccion, setMostrarErrorDireccion] = useState(false);

  const handleParroquiaChange = (event) => {
    setGuardarparroquia(event.target.value);

    if (parroquias.length < 1) {
      setMostrarErrorDireccion(true);
    } else {
      setMostrarErrorDireccion(false);
    }
  };

  const handleMunicipioChange = (event) => {
    const municipioSeleccionado = event.target.value;

    setGuardarmunicipio(municipioSeleccionado);

    if (parroquias.length < 1) {
      setMostrarErrorDireccion(true);
    } else {
      setMostrarErrorDireccion(false);
    }

    // Filtrar parroquias basadas en la provincia y municipio seleccionados
    const parroquiasPorProvinciaYMunicipio = {
      "San José": {
        "San José": [
          "Carmen",
          "Merced",
          "Hospital",
          "Catedral",
          "Zapote",
          "San Francisco de Dos Ríos",
          "Uruca",
          "Mata Redonda",
          "Pavas",
          "Hatillo",
          "San Sebastián",
        ],
        Escazú: ["Escazú", "San Antonio", "San Rafael"],
        Desamparados: [
          "Desamparados",
          "San Miguel",
          "San Juan de Dios",
          "San Rafael Arriba",
          "San Antonio",
          "Frailes",
          "Patarra",
          "San Cristobal",
          "Rosario",
          "Damas",
          "San Rafael Abajo",
          "Gravilias",
          "Los Guido",
        ],
        Puriscal: [
          "Santiago",
          "Mercedes Sur",
          "Barbacoas",
          "Grifo Alto",
          "San Rafael",
          "Candelarita",
          "Desamparaditos",
          "San Antonio",
          "Chires",
        ],
        Tarrazú: ["San Marcos", "San Lorenzo", "San Carlos"],
        Aserrí: [
          "Aserrí",
          "Tarbaca",
          "Vuelta de Jorco",
          "San Gabriel",
          "Legua",
          "Monterrey",
          "Salitrillos",
        ],
        Mora: [
          "Colón",
          "Guayabo",
          "Tabarcia",
          "Piedras Negras",
          "Picagres",
          "Jaris",
          "Quitirrisí",
        ],
        Goicoechea: [
          "Guadalupe",
          "San Francisco",
          "Calle Blancos",
          "Mata de Plátano",
          "Ipis",
          "Rancho Redondo",
          "Purral",
        ],
        "Santa Ana": [
          "Santa Ana",
          "Salitral",
          "Pozos",
          "Uruca",
          "Piedades",
          "Brasil",
        ],
        Alajuelita: [
          "Alajuelita",
          "San Josecito",
          "San Antonio",
          "Concepción",
          "San Felipe",
        ],
        "Vázquez de Coronado": [
          "San Isidro",
          "San Rafael",
          "Dulce Nombre de Jesús",
          "Patalillo",
          "Cascajal",
        ],
        Acosta: [
          "San Ignacio",
          "Guaitil",
          "Palmichal",
          "Cangrejal",
          "Sabanillas",
        ],
        Tibás: [
          "San Juan",
          "Cinco Esquinas",
          "Anselmo Llorente",
          "León XIII",
          "Colima",
        ],
        Moravia: ["San Vicente", "San Jerónimo", "La Trinidad"],
        "Montes de Oca": ["San Pedro", "Sabanilla", "Mercedes", "San Rafael"],
        Turrubares: [
          "San Pablo",
          "San Pedro",
          "San Juan de Mata",
          "San Luis",
          "Carara",
        ],
        Dota: ["Santa María", "Jardín", "Copey"],
        Curridabat: ["Curridabat", "Granadilla", "Sánchez", "Tirrases"],
        "Pérez Zeledón": [
          "San Isidro de El General",
          "El General",
          "Daniel Flores",
          "Rivas",
          "San Pedro",
          "Platanares",
          "Pejibaye",
          "Cajón",
          "Barú",
          "Río Nuevo",
          "Paramo",
          "La Amistad",
        ],
        "León Cortés Castro": [
          "San Pablo",
          "San Andrés",
          "Llano Bonito",
          "San Isidro",
          "Santa Cruz",
          "San Antonio",
        ],
        // ... resto de municipios ...
      },
      Alajuela: {
        Alajuela: [
          "Alajuela",
          "San José",
          "Carrizal",
          "San Antonio",
          "Guácima",
          "San Isidro",
          "Sabanilla",
          "San Rafael",
          "Río Segundo",
          "Desamparados",
          "Turrucares",
          "Tambor",
        ],
        "San Ramón": [
          "San Ramón",
          "Santiago",
          "San Juan",
          "Piedades Norte",
          "Piedades Sur",
          "San Rafael",
          "San Isidro",
          "Ángeles",
          "Alfaro",
          "Volio",
          "Concepción",
          "Zapotal",
          "Peñas Blancas",
          "San Lorenzo",
        ],
        Grecia: [
          "Grecia",
          "San Isidro",
          "San José",
          "San Roque",
          "Tacares",
          "Puente de Piedra",
          "Bolivar",
        ],
        "San Mateo": ["San Mateo", "Desmonte", "Jesús María", "Labrador"],
        Atenas: [
          "Atenas",
          "Jesús",
          "Mercedes",
          "San Isidro",
          "Concepción",
          "San José",
          "Santa Eulalia",
          "Escobal",
        ],
        Naranjo: [
          "Naranjo",
          "San Miguel",
          "San José",
          "Cirrí Sur",
          "San Jerónimo",
          "San Juan",
          "El Rosario",
          "Palmitos",
        ],
        Palmares: [
          "Palmares",
          "Zaragoza",
          "Buenos Aires",
          "Santiago",
          "Candelaria",
          "Esquipulas",
          "La Granja",
        ],
        Poás: [
          "San Pedro",
          "San Juan",
          "San Rafael",
          "Carrillos",
          "Sabana Redonda",
        ],
        Orotina: [
          "Orotina",
          "El Mastate",
          "Hacienda Vieja",
          "Coyolar",
          "La Ceiba",
        ],
        "San Carlos": [
          "Quesada",
          "Florencia",
          "Buenavista",
          "Aguas Zarcas",
          "Venecia",
          "Pital",
          "La Fortuna",
          "La Tigra",
          "La Palmera",
          "Venado",
          "Cutris",
          "Monterrey",
          "Pocosol",
        ],
        Zarcero: [
          "Zarcero",
          "Laguna",
          "Tapesco",
          "Guadalupe",
          "Palmira",
          "Zapote",
          "Brisas",
        ],
        Sarchí: [
          "Sarchí Norte",
          "Sarchí Sur",
          "Toro Amarillo",
          "San Pedro",
          "Rodríguez",
        ],
        Upala: [
          "Upala",
          "Aguas Claras",
          "San José O Pizote",
          "Bijagua",
          "Delicias",
          "Dos Ríos",
          "Yolillal",
          "Canalete",
        ],
        "Los Chiles": ["Los Chiles"],
        Guatuso: ["San Rafael", "Buenavista", "Cote", "Katira"],
        "Río Cuarto": ["Río Cuarto", "Santa Rita", "Santa Isabel"],
        // ... resto de municipios ...
      },
      Cartago: {
        Cartago: [
          "Oriental",
          "Occidental",
          "Carmen",
          "San Nicolás",
          "Aguacaliente o San Francisco",
          "Guadalupe o Arenilla",
          "Corralillo",
          "Tierra Blanca",
          "Dulce Nombre",
          "Llano Grande",
          "Quebradilla",
        ],
        Paraíso: [
          "Paraíso",
          "Santiago",
          "Orosi",
          "Cachí",
          "Llanos de Santa Lucía",
          "Birrisito",
        ],
        "La Unión": [
          "Tres Ríos",
          "San Diego",
          "San Juan",
          "San Rafael",
          "Concepción",
          "Dulce Nombre",
          "San Ramón",
          "Río Azul",
        ],
        Jiménez: ["Juan Viñas", "Tucurrique", "Pejibaye"],
        Turrialba: [
          "Turrialba",
          "La Suiza",
          "Peralta",
          "Santa Cruz",
          "Santa Teresita",
          "Pavones",
          "Tuis",
          "Tayutic",
          "Santa Rosa",
          "Tres Equis",
          "La Isabel",
          "Chirripó",
        ],
        Alvarado: ["Pacayas", "Cervantes", "Capellades"],
        Oreamuno: [
          "San Rafael",
          "Cot",
          "Potrero Cerrado",
          "Cipreses",
          "Santa Rosa",
        ],
        "El Guarco": ["El Tejar", "San Isidro", "Tobosi", "Patio de Agua"],
        // ... resto de municipios ...
      },

      Heredia: {
        Heredia: [
          "Heredia",
          "Mercedes",
          "San Francisco",
          "Ulloa",
          "Varablanca",
        ],
        Barva: [
          "Barva",
          "San Pedro",
          "San Pablo",
          "San Roque",
          "Santa Lucía",
          "San José de la Montaña",
        ],
        "Santo Domingo": [
          "Santo Domingo",
          "San Vicente",
          "San Miguel",
          "Paracito",
          "Santo Tomás",
          "Santa Rosa",
          "Tures",
          "Pará",
        ],
        "Santa Bárbara": [
          "Santa Bárbara",
          "San Pedro",
          "San Juan",
          "Jesús",
          "Santo Domingo",
          "Purabá",
        ],
        "San Rafael": [
          "San Rafael",
          "San Josecito",
          "Santiago",
          "Ángeles",
          "Concepción",
        ],
        "San Isidro": ["San Isidro", "San José", "Concepción", "San Francisco"],
        Belén: ["San Antonio", "La Ribera", "La Asunción"],
        Flores: ["San Joaquín", "Barrantes", "Llorente"],
        "San Pablo": ["San Pablo", "Rincón de Sabanilla"],
        Sarapiquí: [
          "Puerto Viejo",
          "La Virgen",
          "Las Horquetas",
          "Llanuras del Gaspar",
          "Cureña",
        ],
        // ... resto de municipios ...
      },

      Guanacaste: {
        Liberia: [
          "Liberia",
          "Cañas Dulces",
          "Mayorga",
          "Nacascolo",
          "Curubandé",
        ],
        Nicoya: [
          "Nicoya",
          "Mansión",
          "San Antonio",
          "Quebrada Honda",
          "Sámara",
          "Nosara",
          "Belén de Nosarita",
        ],
        "Santa Cruz": [
          "Santa Cruz",
          "Bolsón",
          "Veintisiete de Abril",
          "Tempate",
          "Cartagena",
          "Cuajiniquil",
          "Diriá",
          "Cabo Velas",
          "Tamarindo",
        ],
        Bagaces: ["Bagaces", "La Fortuna", "Mogote", "Río Naranjo"],
        Carrillo: ["Filadelfia", "Palmira", "Sardinal", "Belén"],
        Cañas: ["Cañas", "Palmira", "San Miguel", "Bebedero", "Porozal"],
        Abangares: ["Las Juntas", "Sierra", "San Juan", "Colorado"],
        Tilarán: [
          "Tilarán",
          "Quebrada Grande",
          "Tronadora",
          "Santa Rosa",
          "Líbano",
          "Tierras Morenas",
          "Arenal",
          "Cabeceras",
        ],
        Nandayure: [
          "Carmona",
          "Santa Rita",
          "Zapotal",
          "San Pablo",
          "Porvenir",
          "Bejuco",
        ],
        "La Cruz": ["La Cruz", "Santa Cecilia", "La Garita", "Santa Elena"],
        Hojancha: ["Hojancha", "Monte Romo", "Puerto Carrillo", "Huacas"],
        // ... resto de municipios ...
      },

      Puntarenas: {
        Puntarenas: [
          "Puntarenas",
          "Pitahaya",
          "Chomes",
          "Lepanto",
          "Paquera",
          "Manzanillo",
          "Guacimal",
          "Barranca",
          "Isla del Coco",
          "Cóbano",
          "Chacarita",
          "Chira",
          "Acapulco",
          "El Roble",
          "Arancibia",
        ],
        Esparza: [
          "Espíritu Santo",
          "San Juan Grande",
          "Macacona",
          "San Rafael",
          "San Jerónimo",
          "Caldera",
        ],
        "Buenos Aires": [
          "Buenos Aires",
          "Volcán",
          "Potrero Grande",
          "Boruca",
          "Pilas",
          "Colinas",
          "Chánguena",
          "Biolley",
          "Brunka",
        ],
        "Montes de Oro": ["Miramar", "La Unión", "San Isidro"],
        Osa: [
          "Puerto Cortés",
          "Palmar",
          "Sierpe",
          "Bahía Ballena",
          "Piedras Blancas",
          "Bahía Drake",
        ],
        Quepos: ["Quepos", "Savegre", "Naranjito"],
        Golfito: ["Golfito", "Puerto Jiménez", "Guaycará", "Pavón"],
        "Coto Brus": [
          "San Vito",
          "Sabalito",
          "Aguabuena",
          "Limoncito",
          "Pittier",
          "Gutiérrez Braun",
        ],
        Parrita: ["Parrita"],
        Corredores: ["Corredor", "La Cuesta", "Canoas", "Laurel"],
        Garabito: ["Jacó", "Tárcoles", "Lagunillas"],
        Monteverde: ["Monteverde"],
        // ... resto de municipios ...
      },

      Limón: {
        Limón: ["Limón", "Valle La Estrella", "Río Blanco", "Matama"],
        Pococí: [
          "Guápiles",
          "Jiménez",
          "Rita",
          "Roxana",
          "Cariari",
          "Colorado",
          "La Colonia",
        ],
        Siquirres: [
          "Siquirres",
          "Pacuarito",
          "Florida",
          "Germania",
          "El Cairo",
          "Alegría",
          "Reventazón",
        ],
        Talamanca: ["Bratsi", "Sixaola", "Cahuita", "Telire"],
        Matina: ["Matina", "Batán", "Carrandí"],
        Guácimo: ["Guácimo", "Mercedes", "Pocora", "Río Jiménez", "Duacarí"],
        // ... resto de municipios ...
      },
    };

    const provinciaActual = guardarprovincias;

    setParroquias(
      parroquiasPorProvinciaYMunicipio[provinciaActual]?.[
        municipioSeleccionado
      ] || []
    );
  };

  const renderParroquiaOptions = () => {
    if (!Array.isArray(parroquias) || parroquias.length === 0) {
      return <option value="">No hay parroquias disponibles</option>;
    }
    return parroquias.map((parroquia) => (
      <option key={parroquia} value={parroquia}>
        {parroquia}
      </option>
    ));
  };

  useEffect(() => {
    obtenerTiendasDeCR();
  }, []);

  async function obtenerTiendasDeCR() {
    try {
      const response = await fetch(
        "https://cms-canje-dev.darienc.com/api/tiendas?Pais=CR"
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setTiendas(data.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const [mostrarErrorTienda, setMostrarErrorTienda] = useState(false);
  const [isValidEmailEnviar, setIsValidEmailEnviar] = useState(false);

  const handleTiendaChange = (e) => {
    setSelectedTienda(e.target.value);
    const validarT = e.target.value;
    if (validarT.length === 0) {
      setMostrarErrorTienda(true);
    } else {
      setMostrarErrorTienda(false);
    }
  };

  const handleOnChangeSutbmi = (e) => {
    e.preventDefault();

    if (selectedFiles.length < 1) {
      setMostrarErrorImagen(true);
    }

    if (value.length < 7) {
      setShowMessage(true);
    }

    if (documento.length < 7) {
      setShowMessageCedula(true);
    }

    if (valueEmail.length < 7) {
      setIsValidEmailEnviar(true);
    }

    if (cupon.length < 5) {
      setmostrar(true);
    }

    if (telefono.length < 5) {
      setMostrarErrorTelefono(true);
    }

    if (factura.length < 5) {
      setMostrarfactura(true);
    }

    if (parroquias.length < 1) {
      setMostrarErrorDireccion(true);
    }

    if (direccion.length < 7) {
      setMostrarerrorD(true);
    }

    if (selectedTienda.length === 0) {
      setMostrarErrorTienda(true);
    }

    if (!termsCondition) {
      setTermsConditionError(true);
    }

    // Aquí puedes agregar una condición para enviar los datos solo si todos los campos son válidos
    if (
      value &&
      value.length >= 7 &&
      documento &&
      documento.length >= 7 &&
      valueEmail &&
      valueEmail.length >= 7 &&
      cupon &&
      cupon.length >= 5 &&
      telefono &&
      telefono.length >= 5 &&
      factura &&
      factura.length >= 5 &&
      parroquias &&
      parroquias.length > 0 &&
      direccion &&
      direccion.length >= 7 &&
      selectedFiles &&
      selectedFiles.length >= 1 &&
      termsCondition
    ) {
      enviarDatos();
    }
  };

  return (
    <main
      className={`flex min-h-screen flex-col items-center justify-between p-24 `}
    >
      <div className="">
        <form className="" noValidate onSubmit={handleOnChangeSutbmi}>
          <div className="formulario">
            <div className="mb-4">
              <label
                htmlFor="nombreApellido"
                className={`block text-gray-700 text-sm font-bold mb-2 `}
              >
                Nombre y apellido
              </label>
              <input
                type="text"
                id="nombreApellido"
                name="nombreApellido"
                placeholder="Por favor, ingresa tu nombre completo"
                required
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {showMessage && (
                <p className="mt-2 text-red-500 texto-error">
                  Por favor, ingresa tu nombre completo
                </p>
              )}
            </div>

            <div className="mb-4">
              <label
                htmlFor="documento"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Número de cédula o pasaporte
              </label>
              <input
                type="texto"
                id="documento"
                name="documento"
                value={documento}
                onChange={handleChangeCedula}
                required
                placeholder="Ingresa el número de cédula o pasaporte"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              {showMessageCedula && (
                <p className="mt-2 text-red-500 texto-error">
                  Por favor, ingresa la información completa
                </p>
              )}
            </div>

            <div className="mb-4">
              <label
                htmlFor="correo"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Correo electrónico
              </label>
              <input
                type="email"
                id="correo"
                name="correo"
                required
                placeholder="Ingresa tu correo electrónico"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={valueEmail}
                onChange={handleChangeEmail}
                onBlur={handleBlurEmail}
              />
              {!isValid && errorMessage && (
                <p className="mt-2 text-red-500 texto-error">{errorMessage}</p>
              )}
              {isValidEmailEnviar && (
                <p className="mt-2 text-red-500 texto-error">
                  El formato de correo electrónico no es válido
                </p>
              )}
            </div>

            <div className="mb-4 div-celular">
              <label
                htmlFor="telefono"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Número de teléfono
              </label>
              <div className="select-imagen flex items-center box-checkse selects-pais">
                <select
                  onChange={handleSelectNumero}
                  id="ciudad"
                  name="ciudad"
                  className=" block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="" disabled selected>
                    <img src="/Costa-Rica.png"></img> +506{" "}
                  </option>
                </select>
                <input
                  type="number"
                  id="telefono"
                  name="telefono"
                  value={telefono}
                  onChange={handleOnChange}
                  required
                  placeholder="Número de teléfono"
                  className="w-full shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              {mostrarErrorTelefono && (
                <p className="mt-2 text-red-500 texto-error">
                  Ingresa un número telefónico valido.
                </p>
              )}
            </div>

            <div className="mb-4">
              <label
                htmlFor="cupon"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Número de cupón
              </label>
              <input
                type="number"
                id="cupon"
                onChange={handleOnChangeCupon}
                value={cupon}
                name="cupon"
                required
                placeholder="Ingresa tu número de cupón"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              {mostrar && (
                <p className="mt-2 text-red-500 texto-error">
                  Ingresa el número de cupón tal como aparece en tu voucher.
                </p>
              )}
            </div>

            <div className="mb-4">
              <label
                htmlFor="factura"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Número de factura
              </label>
              <input
                type="number"
                id="factura"
                onChange={handleOnChangeFactura}
                value={factura}
                name="factura"
                required
                placeholder="Ingresa tu número de factura"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              {mostrarfactura && (
                <p className="mt-2 text-red-500 texto-error">
                  Ingresa el número de factura tal como aparece en tu documento.
                </p>
              )}
            </div>

            <div className="tipo-select">
              <div className="mb-4 m-witeh">
                <label
                  htmlFor="factura"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  País
                </label>
                <select
                  id="ciudad"
                  name="ciudad"
                  required
                  className="select-pais block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="Costa Rica" disabled selected>
                    Costa Rica
                  </option>
                </select>
              </div>

              <div className="mb-4 m-witeh">
                <label
                  htmlFor="factura"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Provincia
                </label>
                <select
                  required
                  id="provincias"
                  onChange={handleProvinciaChange}
                >
                  <option value="">Selecciona una provincia</option>
                  {provincias.map((provincia) => (
                    <option key={provincia} value={provincia}>
                      {provincia}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-4 m-witeh">
                <label
                  htmlFor="factura"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Cantón
                </label>
                <select
                  required
                  id="municipios"
                  onChange={handleMunicipioChange}
                >
                  <option value="">Selecciona un cantón</option>
                  {municipios.map((provincia) => (
                    <option key={provincia} value={provincia}>
                      {provincia}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-4 m-witeh">
                <label
                  htmlFor="factura"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Distrito
                </label>
                <select
                  required
                  id="parroquia"
                  onChange={handleParroquiaChange}
                >
                  <option value="">Selecciona un distrito</option>
                  {renderParroquiaOptions()}
                </select>
              </div>
              {mostrarErrorDireccion && (
                <p className="mt-2 text-red-500 texto-error">
                  Ingresa una dirección correcta.
                </p>
              )}
            </div>
            <div className="mb-4 div-tienda">
              <label
                htmlFor="factura"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Tiendas
              </label>
              <select
                id="tiendas-select"
                value={selectedTienda}
                onChange={handleTiendaChange}
                required
              >
                <option value="">Selecciona una tienda</option>
                {tiendas.map((tienda) => (
                  <option key={tienda.id} value={tienda.attributes.Nombre}>
                    {tienda.attributes.Nombre}
                  </option>
                ))}
              </select>
              {mostrarErrorTienda && (
                <p className="mt-2 text-red-500 texto-error">
                  Por favor, selecciona una tienda.
                </p>
              )}
            </div>

            <div className="mb-4">
              <label
                htmlFor="direccion"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Dirección {direccion}
              </label>
              <input
                type="text"
                id="direccion"
                onChange={handleOnChangeDireccion}
                value={direccion}
                name="direccion"
                required
                placeholder="Ciudad, calle, edificio, número de apartamento o casa"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              {mostrarerrorD && (
                <p className="mt-2 text-red-500 texto-error">
                  Por favor, ingresa una dirección correcta
                </p>
              )}
              <p className="alerta-puts">
                {" "}
                <img src="/Icon ionic-md-alert.png" alt="banner" url="/" />{" "}
                Asegúrate de que esta sea la dirección correcta para recibir tus
                productos redimidos.
              </p>
            </div>

            <div className="mb-4">
              <label
                htmlFor="fotoFactura"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Cargar una foto de tu factura
              </label>
              <Dropzone
                onDrop={(acceptedFiles) => {
                  handleAcceptedFiles(acceptedFiles);
                  setMostrarErrorImagen(false);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone custom-dropzone" {...getRootProps()}>
                    <input {...getInputProps()} />

                    <div className="dropzone-content">
                      <div className="dropzone-icon">
                        <i className="ri-upload-cloud-2-fill display-4 text-muted" />
                      </div>
                      <h4>
                        Arrastra el archivo aquí<br></br> o
                        <span class="filepond--label-action"> selecciona</span>{" "}
                        para adjuntar
                      </h4>
                    </div>
                  </div>
                )}
              </Dropzone>
              <div className="list-unstyled mb-0" id="file-previews">
                {selectedFiles.map((f, i) => {
                  return (
                    <Card
                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                      key={i + "-file"}
                    >
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="200"
                              width="200"
                              className="avatar-sm rounded bg-light"
                              alt={f.name}
                              src={f.preview}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  );
                })}
              </div>
              {mostrarErrorImagen && (
                <p className="mt-2 text-red-500 texto-error">
                  Por favor, ingresa una imagen de tu factura
                </p>
              )}
            </div>

            <div className="mb-4">
              <div className="flex items-center box-checks">
                <input
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTermsConditionError(false);
                    }
                    setTermsCondition(e.target.checked);
                  }}
                  type="checkbox"
                  id="terminosCondiciones"
                  name="terminosCondiciones"
                  required
                  className="mr-2 custom-checkbox"
                />
                <p className="terminos">
                  Acepto los{" "}
                  <a onClick={() => setIsOpenTerminos(true)}>
                    términos de uso y política de privacidad
                  </a>
                </p>
              </div>
              {termsConditionError && (
                <p className="mt-2 text-red-500 texto-error">
                  Debe aceptar los términos de uso y política de privacidad.
                </p>
              )}
            </div>

            <div className="mb-4">
              <div className="flex items-center box-checks">
                <input
                  onChange={(e) => {
                    setNewsletter(e.target.checked);
                  }}
                  type="checkbox"
                  id="recibirInformacion"
                  name="recibirInformacion"
                  className="mr-2"
                />
                <p className="terminos">
                  Acepto recibir información y promociones a este correo por
                  parte de Samsung Latinoamérica.
                </p>
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Redimir cupón
          </button>
        </form>
      </div>
      <footer>
        <p className="terminos">2024 Samsung Latam</p>
      </footer>
      <div>
        {isOpen && (
          <div className="popup-overlay">
            <div className="popup-content">
              <h3>Centro de Canje</h3>

              <h2>¡Tu cupón está en proceso!</h2>
              <p>
                Estamos revisando tu solicitud.
                <br />
                ¡Mantente atento a tu correo para más detalles!
              </p>
              <p> Tu producto será enviado entre 48 a 72 horas hábiles</p>
              <button onClick={recargarPagina}>Entendido</button>
            </div>
          </div>
        )}
      </div>

      <div>
        {isOpenTerminos && (
          <div className="popup-overlay">
            <div className="popup-content">
              <h3>Términos de Uso y Política de Privacidad</h3>

              <h2>Lorem Ipsum</h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
              </p>
              <button onClick={() => setIsOpenTerminos(false)}>
                Entendido
              </button>
            </div>
          </div>
        )}
      </div>
    </main>
  );
}
